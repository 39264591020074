import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import usePageQuery from '../../pageQuery'
import headerJson from '../../header.json'

import * as S from './styles'

import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
const { qrCodeAberturaDeContaDebentures } = qrcodeInvestimentsData

type HeroProps = {
  setIsModalOpen: (value: boolean) => void;
}

const Hero = ({ setIsModalOpen }: HeroProps) => {
const data = usePageQuery()

  return (
    <S.Section>
      <div className='container py-5'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 offset-xl-1 order-md-last mb-4 mb-md-0'>
            <Img fluid={data.bannerHeroDebentures.fluid} alt='Tela do Super App da Inter Invest mostrando como investir em Debêntures.' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <div className='bread mb-3'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#6A6E81' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/renda-fixa/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
              >
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
              <Link
                to='/pra-voce/investimentos/renda-fixa/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mr-2'
              >
                {headerJson.breadcrumb[1]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[2]}</p>
            </div>
            <div>
              <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 font-sora mb-3'>Debêntures Inter</h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 font-inter mb-0'>
                Debêntures são títulos emitidos por empresas com o objetivo de captar recursos.
                São uma alternativa interessante para diversificar a sua carteira de investimentos.
              </p>
            </div>
            <div>
              <a
                href={qrCodeAberturaDeContaDebentures.deeplink}
                className='btn btn--orange btn--lg rounded-3 mw-100 mt-4 d-flex d-md-none'
              >
                Conheça
              </a>
              <button
                className='btn btn--orange btn--lg rounded-3 mw-100 mt-4 d-none d-md-flex'
                onClick={() => {
                    setIsModalOpen(true)
                }}
              >
                Conheça
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
